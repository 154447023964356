<template>
    <CompTable title="查阅情况统计" :columns="columns" :table-api="api"></CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "../communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            api: `/gateway/api/symanage/pc/report/listReportView?itemId=${this.$core.getUrlParam("i")}`,
            columns: [
                {
                    title: "机构",
                    minWidth: 300,
                    align: "center",
                    key: "orgName",
                },
                {
                    title: "在职管理员数",
                    minWidth: 150,
                    align: "center",
                    key: "workStaffNum",
                },
                {
                    title: "已阅人数",
                    minWidth: 150,
                    align: "center",
                    key: "viewUser",
                },
                {
                    title: "阅读次数",
                    minWidth: 150,
                    align: "center",
                    key: "view",
                },
                {
                    title: "操作",
                    minWidth: 150,
                    fixed: "right",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "info",
                                    size: "small",
                                },
                                style: "margin:3px",
                                nativeOn: {
                                    click: () => {
                                        Route.jump("/weeklyreportsreaddetail", {
                                            i: this.$core.getUrlParam("i"),
                                            c: params.row.orgCode,
                                        })
                                    },
                                },
                            },
                            "查阅明细"
                        )
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
